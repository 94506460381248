import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";
import Icon from "../../components/Icons/icon";

const ContinuingProvisionBenefitOrLoas = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Benefício de Prestação Continuada ou Loas"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          {
            page: "/beneficio-prestacao-continuada-ou-loas",
            name: "Benefício de Prestação Continuada ou Loas",
          },
        ]}
      >
        {isDesktop && <Aside />}

        <P.Article>
          <p>
            Benefício de Prestação Continuada ou Loas é o Benefício que garante
            um salário mínimo à pessoa com deficiência e ao idoso com 65 anos ou
            mais que não possuem condições financeiras de se manter.
          </p>

          <p>
            Terá direito ao BPC o brasileiro nato ou o brasileiro naturalizado,
            assim como os de nacionalidade portuguesa, desde que, comprovem
            residência fixa no Brasil e consigam comprovar está em situação de
            hipossuficiência.
          </p>

          <p>Condições:</p>

          <P.List>
            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>
                <b>Idoso:</b> idade igual ou superior a 65 anos, tanto para
                homens como para mulheres, neste caso não é necessário a
                comprovação de incapacidade física;
              </span>
            </li>

            <li>
              <Icon name="ArrowRightSmall" />{" "}
              <span>
                <b>Pessoa com deficiência:</b> Em qualquer idade - desde que
                apresentem impedimento de longo prazo (mínimo de 2 anos) que
                podem ser de natureza física, intelectual ou sensorial, mental,
                desde que possam obstruir sua participação plena e efetiva na
                sociedade em igualdade de condições com as demais pessoas.
              </span>
            </li>
          </P.List>

          <p>
            Para se ter acesso ao benefício, não se faz necessário ter
            contribuído ao INSS. Todavia, O beneficiário do BPC/LOAS não tem
            direito ao 13º salário e não é capaz de deixar pensão por morte aos
            herdeiros. O BPC/Loas também não pode ser acumulado com nenhum outro
            tipo de benefício não pode ser acumulado com nenhum outro tipo de
            benefício no âmbito da Seguridade Social (aposentadorias, Aux.
            doença, pensão por morte) ou de outro regime, exceto com benefícios
            da assistência médica, pensões especiais de natureza indenizatória
            ou remunerações de contrato de aprendizagem.
          </p>

          <p>
            Para o BPC família envolve o requerente, o cônjuge ou companheiro,
            os pais e, na ausência de um deles, a madrasta ou o padrasto, os
            irmãos solteiros, os filhos e enteados solteiros e os menores
            tutelados. Desta forma, o Grupo Familiar do BPC, para fins deste
            benefício assistencial, é composto por qualquer um desses membros,
            sendo necessário que convivam no mesmo lar.
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Benefício de Prestação Continuada ou Loas"
    description="Assessoria jurídica em Benefício de Prestação Continuada (LOAS). Ajudamos a garantir seus direitos a benefícios assistenciais, oferecendo orientação clara e suporte confiável em todo o processo."
  />
);

export default ContinuingProvisionBenefitOrLoas;
